import React from 'react'

const Portfolio = () => {
  return (
    <div className="right-bar right-bar-padding flex-col">
    <div className="title-part animate__animated animate__slideInUp">
      <h1>Portfolio</h1>
    </div>
    <h1 className='margin-top-60'>Working on Portfolio page...</h1>
        <h2 className='margin-top-30'>You can check my Work here</h2>
        <div className='site-section'>
           <ol>
           <li><a href='https://www.anayase.com' target={'_blank'}>anayse.com</a></li>
            <li><a href='http://www.goldenmartbd.com' target={'_blank'}> goldenmartbd.com</a></li>
            <li><a href='http://spis.himon.me' target={'_blank'}> Spis Website</a></li>
            <li><a href='http://adminreport.goldenmartbd.com' target={'_blank'}> Report Genarate website</a></li>
            <li><a href='https://himonhimu.github.io/NumbertoText/' target={'_blank'}> number to text</a></li>
           </ol>
        </div>
        <div className="code-section animate__animated animate__slideInUp">
            <pre>& many more for practice; for some office work which was good for practice</pre>
        </div>
  </div>
  )
}

export default Portfolio