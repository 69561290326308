import React from 'react'
import Circle from '../elements/Circle'

export const Resume = () => {
    function myFuncton() {
        
    }  


  return (
    <div className="right-bar right-bar-padding flex-col ">
          <div className="title-part animate__animated animate__slideInUp">
            <h1>Resume</h1>
            {/* <!-- Resume  --> */}
          </div>
          <div className="education-section margin-top-80 flex-col">
            <div className="edu-title">
              <h2>Educational Qualifications</h2>
            </div>
            <div className="edu-card margin-top-30">
              <div className="left-part">
                <h4>2014 - 2015</h4>
              </div>
              <div className="right-part">
                <h2>Master of Geography & Environment (MSc)</h2>
                <p className="edu-card-m-top">National University, Bangladesh</p>
                <p className="edu-card-m-top edu-gray">I have done MSc in Geography & Environment. </p>
              </div>
            </div>
            <div className="edu-card margin-top-30">
              <div className="left-part">
                <h4>2009 - 2013</h4>
              </div>
              <div className="right-part">
                <h2>Hon's in Geography & Environment (BSc)</h2>
                <p className="edu-card-m-top">National University, Bangladesh</p>
                <p className="edu-card-m-top edu-gray">I have done BSc in Geography & Environment.</p>
              </div>
            </div>
            <div className="edu-card margin-top-30">
              <div className="left-part">
                <h4>2007 - 2009</h4>
              </div>
              <div className="right-part">
                <h2>Higher Secondary School Certificate (HSC)</h2>
                <p className="edu-card-m-top">Board of Intermediate & Secondary Education, Dhaka</p>
                <p className="edu-card-m-top edu-gray">I have completed Higher Secondary School Certificate from Hathazari Government college and it took me two years to get the degree. I was a student with Arts background.</p>
              </div>
            </div>
           
            <div className="edu-card margin-top-30">
              <div className="left-part">
                <h4>2006 - 2007</h4>
              </div>
              <div className="right-part">
                <h2> Secondary School Certificate (SSC)</h2>
                <p className="edu-card-m-top">Board of Intermediate & Secondary Education, Dhaka</p>
                <p className="edu-card-m-top edu-gray">I completed Secondary School Certificate from Katirhat High School. I was a student of commerce background. I completed SSC in 2007.</p>
              </div>
            </div>
          
          
            <div className={ 'title-part margin-top-80 animate__animated animate__slideInUp'}>
              <h1 onScroll={myFuncton()}>Skill Point</h1>
            </div>
            <div className="skill-holder scroll-event margin-top-30">
              {/* <div className="progress-wrapper">
                <h3>HTML5</h3>
                <div className="circle-progress" id="circle01">
                  <h3 className="inner-text">0%</h3>
                </div>

              </div> */}
              <Circle head={'HTML5'} percent={92} />
              <Circle head={'CSS3'} percent={75} />
              <Circle head={'Bootstrap'} percent={55} />
              <Circle head={'JavaScript'} percent={88}/>
              <Circle head={'TypeScript'} percent={65}/> 
              <Circle head={'ReactJS'} percent={75}/>
              <Circle head={'NextJS'} percent={52}/>
              <Circle head={'NextJS'} percent={52}/>
              <Circle head={'Redux'} percent={50}/>
              <Circle head={'NodeJS'} percent={92}/>
              <Circle head={'NPM'} percent={75}/>
              <Circle head={'ExpressJS'} percent={70}/>
              <Circle head={'Git'} percent={55}/>
              <Circle head={'PostMan'} percent={70}/>
              <Circle head={'Webpack'} percent={55}/>
              <Circle head={'Firebase'} percent={60}/>
              <Circle head={'mysql'} percent={75}/>
              <Circle head={'cPanel'} percent={85}/>
              <Circle head={'Nginx'} percent={55}/>
              <Circle head={'Linux'} percent={50}/>
              <Circle head={'XD'} percent={85}/>
              <Circle head={'Photoshop'} percent={95}/>
              <Circle head={'Illustrator'} percent={92}/>
              <Circle head={'Figma'} percent={65}/>
              <div className="progress-wrapper visibility-none">
                <h3>fdf</h3>
                <div className="circle-progress" id="circle24">
                  <h3 className="inner-text">0%</h3>
                </div>
              </div>
              {/* 

          

              <div className="progress-wrapper">
                <h3>Figma</h3>
                <div className="circle-progress" id="circle23">
                  <h3 className="inner-text">0%</h3>
                </div>
              </div>
              <div className="progress-wrapper visibility-none">
                <h3>Figma</h3>
                <div className="circle-progress" id="circle24">
                  <h3 className="inner-text">0%</h3>
                </div>
              </div> */}
            </div>
          </div>
        </div>
  )
}

function useOnScreen(option) {
    const ref = React.useRef();
    const [visible, setVisble] = React.useState()
    React.useEffect(()=>{
      const observer = new IntersectionObserver(([entry])=>{
        setVisble(entry.isIntersecting);

      }, option)

      if (ref.current) {
        observer.observe(ref.current)
      }

      return ()=>{
        if (ref.current) {
          observer.unobserve(ref.current)
        }
      }
    },[ref, option])

    return [ref, visible]
}