import React, { useEffect } from 'react'
import './circle.css'

const Circle = ({head, percent}) => {
  
    function circleAmimation(mpercent) {
        let progressViewAll = document.querySelectorAll('.circle-progress');
        progressViewAll.forEach(element => {
            let myText = element.children[0];
            let startValue = 0;
            let constValue = parseInt(myText.getAttribute('topercent'));
            let speed = 100 - parseInt(myText.getAttribute('topercent'));
            let myProgress = setInterval(() => {
              startValue++
              myText.innerHTML = startValue + ' %'
              element.style.background = `conic-gradient(var(--primary-color) ${startValue * 3.6}deg, rgb(234, 234, 234) 0deg)`
              if (constValue === startValue) {
                clearInterval(myProgress)
              }
    
            }, speed);
        });
        
      }
     
      useEffect(()=>{
        
        var div = document.querySelector(".whole-body");
      let tempValue = 0
      div.onscroll = function (e) {
        if (e.target.scrollTop > 652 && tempValue === 0) {
            circleAmimation()
          tempValue++
        }

        if (e.target.scrollTop < 652  && tempValue === 1) {
          tempValue = 0
        }

      }
      },[])

  return (
    <div className="progress-wrapper">
    <h3>{head}</h3>
    <div className="circle-progress">
      <h3 className="inner-text" topercent={percent}>0%</h3>
    </div>

  </div>
  )
}

export default Circle