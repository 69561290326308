import React, { useEffect, useRef, useState } from 'react'
import Gdesing from '../icons/014-design-1.svg'
import UUDesign from '../icons/001-ui-design.svg'
import Wdesign from '../icons/008-improvement.svg'
import Wdevelop from '../icons/011-software-development.svg'
import ReviewCard from '../elements/ReviewCard'
import Client01 from '../assets/client (1).jpeg'
import Client02 from '../assets/client (2).jpeg'

const About = () => {
    const [idoref, visible] = useOnScreen({threshold:0.5})
    const [reviewref, mvisible] = useOnScreen({threshold:0.5})

    return (
        <div className="right-bar right-bar-padding flex-col ">
            <div className="title-part animate__animated animate__slideInUp">
                <h1>About Me</h1>
            </div>
            <div className="intro-part">
                
                <div className="intro-left">
                    <h2 className="animate__animated animate__slideInUp">I'm <span className="name">Himon Miah</span>, A Full Stack Web Developer!</h2>
                    <p className="com-margin animate__animated animate__slideInUp">I am able to solve complex logic or other tasks related to web development. I have a perfect understanding of how JavaScript, HTML, and CSS work. I have a lot of experience working with up-to-date technologies especially React JS. I have powerful knowledge HTML, CSS, SCSS, Bootstrap, Tailwind CSS, JavaScript ES6, TypeScript, ReactJS, Redux, NodeJS, NPM, ExpressJS, MongoDB, PostMan, Webpack, Firebase, Figma, XD, PhotoShop, Illustrator, Linux OS. <br/> <br/>
                        My favorite in this programming sector is to fix ‍any Errors. Because in every error there is hope to learn something new. I won't quit until I can fix the error. In this programming life, I have tried to solve all kinds of problems and have succeeded. My policy is to stick with it until the problem is solved.</p>
                </div>
                <div className="intro-right animate__animated animate__slideInUp">
                    <p>Name : Himon Miah</p>
                    <p>Email : kmhimu87@gmail.com</p>
                    <p>Age : 32</p>
                    <p>Nationality : Bangladeshi</p>
                    <p>Language : English, Bengali</p>
                    <p>From : Dhaka, Bangladesh</p>
                </div>
            </div>
            {/* <div>my ref</div> */}
            <div className={visible ? "title-part margin-top-80 animate__animated animate__slideInUp":'title-part'}>
                <h1  >What i do</h1>
            </div>

            <div  ref={idoref} className="card-section">
                <div className={visible ? "card width-50 margin-top-30 animate__animated animate__fadeInRight":'card width-50 margin-top-30'}>
                    <div className="icon-side">
                        <img src={Wdesign} alt="web design" />
                    </div>
                    <div className="text-side ">
                        <h2>Web Design</h2>
                        <p className="card-description">I use design programs to create visual elements. Website designers usually have expertise in UI, or user interface, which means I strategically design a site that's intuitive and easy for visitors to navigate.</p>
                    </div>
                </div>
                <div className={visible ? "card width-50 margin-top-30 animate__animated animate__fadeInLeft":'card width-50 margin-top-30'}>
                    <div className="icon-side">
                        <img src={Wdevelop} alt="web development" />
                    </div>
                    <div className="text-side ">
                        <h2>Web Development</h2>
                        <p className="card-description">Web developers create and maintain websites. I am also responsible for the site's technical aspects, such as its performance and capacity, which are measures of a website's speed and how much traffic the site can handle.</p>
                    </div>
                </div>
                <div className={visible ? "card width-50 margin-top-30 animate__animated animate__fadeInLeft":'card width-50 margin-top-30'}>
                    <div className="icon-side">
                        <img src={UUDesign} alt="ui ux design" />
                    </div>
                    <div className="text-side ">
                        <h2>UI/UX Design</h2>
                        <p className="card-description">Web developers create and maintain websites. I am also responsible for the site's technical aspects, such as its performance and capacity, which are measures of a website's speed and how much traffic the site can handle.</p>
                    </div>
                </div>
                <div  className={visible ? "card width-50 margin-top-30 animate__animated animate__fadeInRight":'card width-50 margin-top-30'}>
                    <div className="icon-side">
                        <img src={Gdesing} alt="graphics development" />
                    </div>
                    <div className="text-side ">
                        <h2>Graphic Design</h2>
                        <p className="card-description">Web developers create and maintain websites. I am also responsible for the site's technical aspects, such as its performance and capacity, which are measures of a website's speed and how much traffic the site can handle.</p>
                    </div>
                </div>
            </div>

            <div  className="title-part margin-top-80 animate__animated animate__slideInUp">
                <h1>Client Reviews</h1>
            </div>
            <div ref={reviewref} className="review-section margin-top-60">
                <div  className={mvisible ? "review-holder animate__animated animate__fadeInRight":'review-holder'}>
                    <ReviewCard Rname={'Marjana Djokic'} Rprofession='Professional with developed planning skills' Rquote={'"Professional with developed planning skills, analytical, responsible and conscientious, focused on achieving results. Communicative, kind, reliable and team-oriented."'} Cimage={Client01}/>
                    <ReviewCard Rname={'Jhon Smith'} Rprofession='Professional with developed planning skills' Rquote={'"Professional with developed planning skills, analytical, responsible and conscientious, focused on achieving results. Communicative, kind, reliable and team-oriented."'} Cimage={Client02}/>
                </div>
            </div>

        </div>
    )
}

export default About

function useOnScreen(option) {
    const ref = React.useRef();
    const [visible, setVisble] = React.useState()
    React.useEffect(()=>{
      const observer = new IntersectionObserver(([entry])=>{
        //console.log(entry)
        setVisble(entry.isIntersecting);

      }, option)

      if (ref.current) {
        observer.observe(ref.current)
      }

      return ()=>{
        if (ref.current) {
          observer.unobserve(ref.current)
        }
      }
    },[ref, option])

    return [ref, visible]
}