import React from 'react'
const Blogs = () => {
  return (
    <div className="right-bar right-bar-padding flex-col">
    <div className="title-part animate__animated animate__slideInUp">
      <h1>Blog Point</h1>
    </div>
    <h1 className='margin-top-60'>Working on blog page...</h1>
  </div>
  )
}

export default Blogs