import React from 'react'
import Resume from "../assets/Himon Miah.pdf";
const Home = () => {
  return (
    <div className="right-bar">
    <div className="content-wrapper"> 
      <h1 className="animate__animated animate__slideInUp">Hi, I am <span className="name">Himon Miah</span></h1> 
      <h4 className="animate__animated animate__slideInUp">Full Stack Web Developer and Web Application specializing in front-end and back-end development. Experienced
        with all stages of the development cycle for dynamic websites. Well versed in numerous programming languages
        JavaScript ES6 Nodejs, structured language HTML5 CSS3, Libraries REACT-JS With MongoDB Database.</h4>
        <div className="icon-section animate__animated animate__slideInUp">
          <a href="https://www.facebook.com/himon143/" target='_blank'><i className="fa-brands fa-facebook"></i></a>
          <a href="https://github.com/himonhimu" target='_blank'><i className="fa-brands fa-github"></i></a>
          <a href="mailto:kmhimu@gmail.com" ><i className="fa-solid fa-envelope"></i></a>
          <a href="https://www.linkedin.com/in/himon-himu-95652a220/" target='_blank'><i className="fa-brands fa-linkedin"></i></a>
        </div>
        <div className="button-section animate__animated animate__slideInUp">
        {/* <a href={Resume} download>
          <button className="button s-button">Download CV</button>
        </a> */}
         <a href={Resume} download className='dload-button'>
            <button><i className="fa-regular fa-circle-down"></i> <span className="d-text">Download CV</span></button>
            </a>
        </div>
        <div className="code-section animate__animated animate__slideInUp">
            <pre>$ sudo npm i react</pre>
        </div>
    </div>
  
  </div>
  )
}

export default Home