import React from 'react'

const ReviewCard = ({Rname, Rprofession, Rquote, Cimage}) => {
    return (
        <div className="review-card width-50">
            <div className="info">
                <img src={Cimage} alt={Rname} />
                <div className="info-text">
                    <h3>{Rname}</h3>
                    <p>{Rprofession}</p>
                </div>
            </div>
            <div className="review com-margin">
                <p>{Rquote}</p>
            </div>
        </div>
    )
}

export default ReviewCard