import React, { useRef } from 'react'
import emailjs from '@emailjs/browser';

const Contact = () => {
  const form = useRef()
  function onFormSubmit(e) {
    e.preventDefault()
    const validEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    const mform = e.target
    const email = mform.email.value
    const mtest = validEmail.test(email);
    if (mtest) {
      // mform.reset()
      emailjs.sendForm('service_tv4oz81', 'template_mdczyay', form.current, 'Tw_Xzn00c68T-lzNW')
      .then((result) => {
          window.alert('Information Submitted Successfully.')
          mform.reset()
      }, (error) => {
          window.alert('Something went Wrong. Please Try Latter')
      });
    }else{
      window.alert('Please Enter Valid Email & Try Again!')
    }
    
   
  }

  return (
    <div className="right-bar right-bar-padding flex-col">
    <div className="title-part animate__animated animate__slideInUp">
      <h1>Contact</h1>
    </div>

    <div className="contact-holder margin-top-60">
      <div className="leftinfo">
          <h3>ADDRESS</h3>
          <div className="text-info">
            <p>Lamapara, Shibu Market, Narayanganj, Dhaka, Bangladesh.</p>
          <p><i className="fa-solid fa-phone add-phone"></i> +8801911824143</p>
          <p> <i className="fa-solid fa-envelope add-mail"></i>kmhimu87@gmail.com</p>
          </div>
          <div className="icon-section animate__animated animate__slideInUp">
          <a href="https://www.facebook.com/himon143/" target='_blank'><i className="fa-brands fa-facebook"></i></a>
          <a href="https://github.com/himonhimu" target='_blank'><i className="fa-brands fa-github"></i></a>
          <a href="https://www.linkedin.com/in/himon-himu-95652a220/" target='_blank'><i className="fa-brands fa-linkedin"></i></a>
          </div>
      </div>
      <div className="contact-form animate__animated animate__fadeInRight">
        <h3>SEND ME A NOTE</h3>
        <form ref={form} className="form-wrapper" onSubmit={onFormSubmit}>
          <input name='subject' type="text" placeholder="Subject" required/>
        <div className="name-email">
          <input name='from_name' type="text" placeholder="name" required/>
          <input name='email' type="text" placeholder="email" required/>
        </div>
        <textarea  name="message" id="" cols="30" rows="10" required></textarea> 
        <button className="send-button">SEND</button>
        </form>
      </div>
  </div>
  </div>
  )
}

export default Contact