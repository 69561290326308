import './App.css';
import './css/styles.css'
import './css/responsive.css'
import Menu from './components/Menu';
import Home from './components/Home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import About from './components/About';
import { Resume } from './components/Resume';
import Blogs from './components/Blogs';
import Contact from './components/Contact';
import Portfolio from './components/Portfolio';
import { lazy, Suspense } from 'react';
const MyLazyComponent = lazy(() => import('./components/MyLazyComponent'));
// import MyLazyComponent from './components/MyLazyComponent';



function App() {

  function onBarClick(params) {
    // let crossButton = document.querySelector('.cros-button')
    let menuitems = document.querySelector('.left-bar')
    menuitems.classList.add('leftbar-active')
    menuitems.classList.remove('remove-animation')

  }

  return (
    <div className="App">
      <div className="whole-body">
        <div className="container">
          <div className="content-wrapper">
            <div className="bar-class" onClick={onBarClick}>
              <i className="fa-solid fa-bars"></i>
              <a style={{ textDecoration: 'none' }} href='/'><p>Himon.me</p></a>
            </div>

            <BrowserRouter>
              <Menu />
              {/* <MyLazyComponent/> */}

              <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/about' element={<About />} />
                <Route path='/resume' element={<Resume />} />
                <Route path='/portfolio' element={<Portfolio />} />
                <Route path='/blogs' element={<Blogs />} />
                <Route path='/contact' element={<Contact />} />
                <Route path='/contact/:myparam' element={<Contact />} />
              </Routes>
            </BrowserRouter>
            {/* <Home /> */}


          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
