import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import ProfileImage from '../assets/himonprofile1.jpg'

const Menu = () => {
  function onCroseClick() {
    let menuitems = document.querySelector('.left-bar')
    menuitems.classList.remove('leftbar-active')
    menuitems.classList.add('remove-animation')
  }
  function onMenuClick (e) {
    onCroseClick()
    var div = document.querySelector(".whole-body");
    div.scrollTo({ top: 0, behavior: "smooth" });
  }

  return (
    <div className="left-bar">
      <div className="cros-button" onClick={onCroseClick}>
        <i className="fa-regular fa-circle-xmark"></i>
      </div>
      <div className="profile-circle-gradiant">
        <div className="profile-circle">
          {/* <!-- <img className="profile-image" src="/images/IMG_0668.JPG" alt=""> --> */}
          <img className="profile-image" src={ProfileImage} alt="Profile Image" />
        </div>

      </div>

      <div className="menu-items">
        <ul>
          <li onClick={onMenuClick}><NavLink to={'/'}>Home</NavLink></li>
          <li onClick={onMenuClick}><NavLink to={'/about'}>About</NavLink></li>
          <li onClick={onMenuClick}><NavLink to={'/resume'}>Resume</NavLink></li>
          <li onClick={onMenuClick}><NavLink to={'/portfolio'}>portfolio</NavLink></li>
          <li onClick={onMenuClick}><NavLink to={'/blogs'}>Blogs</NavLink></li>
          <li onClick={onMenuClick}><NavLink to={'/contact'}>Contact</NavLink></li>
        </ul>
      </div>
    </div>
  )
}

export default Menu